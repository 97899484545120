import { tryGeCombinedtDateExpression, getCombinedExpressionByID, getDateExpressionValue } from './helpers.dateExpressions.ts';
import { getOperatorsList, prettifyFilterItem, getDefaultOperator, parseValueFromServer, filterItemToString, getOperatorTitle, parseInListNumber } from './helpers.filterUtils.ts';
import { stringUtils } from 'o365-utils'
import { DistinctHandler } from './DistinctHandler.ts';
import  ExistsObject  from './helpers.ExistsObject.ts';
import {isNumbersInList} from './helpers.values.ts';
import SearchFunction from './helpers.SearchFunction.ts';
import FullText from './helpers.FullText.ts';
//import { $t } from 'o365-utils';
// import {tryGeCombinedtDateExpression, getCombinedExpressionByID, getDateExpressionValue} from 'o365.modules.dateExpressions.ts';
// import {getOperatorsList,prettifyFilterItem,getDefaultOperator,parseValueFromServer,filterItemToString,getOperatorTitle,parseInListNumber} from 'o365.modules.filterUtils.ts';
// import stringUtils from 'o365.modules.utils.string.js';
// import { DistinctHandler } from 'o365.modules.DistinctHandler.js';

export default class FilterItem {
    private _dateExpression:string|null = null;
    private _dateExpressionObject:any = null;
    private _column:string;
    private _operator:string;
    private _selectedValue:any = null;
    private _limitedValue:any = null;
    private _expressionValue:any = null;
    private _combinedStartValue:any = null;
    private _combinedEndValue:any = null;
    private _operators: Array<any> = [];
    private _applied: boolean = false;
    private _distinctHandler: DistinctHandler|null = null;
    private _nullValueOperators = ['isnull', 'isnotnull', 'isnotblank', 'isblank', 'not_exists_clause'];
    private _existsObject:ExistsObject|null = null;
    private _prettyString:string|null = null;
    private _searchFunction:SearchFunction|null = null;
    private _fullText:FullText|null = null;
    private _showDistinct:boolean = true;
    private _defaultOperator:string|null = null;
    private _distinctOptions = {
        sortAlphabeticallyDirection:'asc',
        sortOnCountDirection:'desc',
        sortOnCount:true,
        hideCounts:false,
        column:"",
        targetColumn:null,
        existsBinding:"",
        targetColumnType:""//"string"
    }
    private _excluded = false;
    useAlias = false;
    useExist:boolean = false;
   
    get fullText(){
        return this._fullText;
    }

    get disableAutoComplete(){
        if(this.fullText) return true;
        return false;
    }

    get searchFunction(){
        return this._searchFunction;
    }

    set distinctTargetColumnType(pValue:string){
        this._distinctOptions.targetColumnType = pValue;
    }
    get distinctTargetColumnType(){
        if(!this._distinctOptions.targetColumnType && this.exist){
            return this.exist.targetColumnType;
        }
        return this._distinctOptions.targetColumnType;
    }

    options:any;
    set showDistinct(pstate:boolean){
        this._showDistinct = pstate;
    }

    get showDistinct(){
        if(this.searchFunction){
            return false;
        }

        return this._showDistinct;
    }

    get type(){
        if(this.operator == "full_text"){
            return "function";
        }
        return "expression"
    }

    get field() {
        return this.column;
    }
    get alias(){
        if(this.useAlias || this.isInListUsed){
            if(this.distinctTargetColumnType == "number" && this.isValueTypeNumber)
            return this._distinctOptions.targetColumn??this.column;


        }
       

        return this.column;
    }

    /**
     * Indicates that the column either comes from sub select or properties.
     */
    get isExtarnal() {
        return this.column.includes('.');
    }

    get isInListUsed(){
        return ['inlist','notinlist','exists_clause'].indexOf(this.operator) > - 1
    }
    get title() {
        if(this.searchFunction && !this.selectedValue && this.searchFunction.label) return this.searchFunction.label;
        return this.caption;
    }
    get isBit() {
        return ['bit', 'boolean'].indexOf(this.valueType) > -1
    }
    get isDate() {
        return ['date', 'datetime'].indexOf(this.valueType) > -1
    }
    get isTime() {
        return ['time'].indexOf(this.valueType) > -1
    }
    get isNumber() {
        return ['number'].indexOf(this.originalType) > -1
    }

    get column() {
        return this._column;
    }

    get disabled() {
        return false
    }

    get showFieldsSelector(){
        if(!this.isNumber) return false;
        if(['between','inlist','notinlist'].indexOf(this.operator) > - 1){
            return false;
        }

        return true;
    }

    get isValueTypeNumber(){
        if(!this.selectedValue && this.selectedValue !== 0) return false;
        if(this.selectedValue.constructor == Array){
            return this.selectedValue.every(element => {
                return typeof element === 'number';
            });
        }

        if(typeof this.selectedValue === 'number'){
            return true;
        }

        return false;


    }

    /** When set to true this item will be excluded from the filter string  */
    get excluded() {
        return this._excluded;
    }
    set excluded(value: boolean) {
        this._excluded = value;
    }

    get operator() {
        return this._operator;
    }

    get distinctOptions(){
        if(this._distinctOptions){
        
            this._distinctOptions.column = this.column;
         
            return this._distinctOptions;
        }
        return {
            column:this.options.distinct.column??this.column
        }
    }



    get shortOperator(){
        if(this.operator.indexOf("between") > -1){
            return null;
        }
        if(this.isNullValueOperator) return null;

        if(this.operator.indexOf("notcontains") > -1){
            return "!";
        }
        if(this.operator.indexOf("contains") > -1){
            return null;
        }
        if(this.operator.indexOf("begin") > -1 || this.operator.indexOf("end") > -1){
            return null;
        }
        if(this.operator.indexOf("inlist") > -1 || this.operator == "exists_clause"){
            return null;
        }

        if(this.operator == "equals" || this.operator == "dateequals" || this.operator == 'timeequals'){
            return "=";
        }
        if(this.operator == "notequals" || this.operator == "datenotequals" || this.operator == "numbernotequals"){
            return "!=";
        }
        if(this.operator.indexOf("greaterthanorequal") > -1){
            return ">=";
        }
        if(this.operator.indexOf("lessthanorequal") > -1){
            return "<=";
        }
        if(this.operator.indexOf("greaterthan") > -1){
            return ">";
        }
        if(this.operator.indexOf("lessthan") > -1){
            return "<";
        }
        if(this.operator == 'full_text'){
            return null;
        }

        return this.operatorTitle;
    }

    set operator(pOperator) {
         //if(pOperator == "isblank"  && this.useExist){
           // pOperator = 'not_exists_clause'
       // }
        
        this._operator = pOperator;
        if(this.isNullValueOperator){
            this._selectedValue = this._operator;
        }


        if(this._existsObject && this.useExist){
            this._existsObject.operator = pOperator;
        }
        
    }

    get disabledValue() {
        if (!this.selectedValue) return null;
        //inlist displayed caption and count
        if (this.isBit && this.selectedValue) return this.label;
        
        //if (this.operator == this.defaultOperator && !this._applied) return null;

        if(this._dateExpression) return this.label;

        if (['isnull', 'isnotnull', 'isnotblank', 'isblank'].indexOf(this.operator) > -1 ) {
            return this.label;
        }
       

        if (this.isInListUsed && this.selectedValue.constructor == Array) {
            if (+this.selectedValue.length > 0)
                return this.caption + " (" + this.selectedValue.length + ")";
        }
        if (this.operator.indexOf("between") > -1 && this.selectedValue.constructor == Array) {
            return this.label;
        }
        if(this.operator === "exists_clause") return null;

        return this.label;
    }

    get disabledTitle(){
      
        if (!this.selectedValue) return null;
        if (this.isBit && this.selectedValue) return this.operatorTitle;
        //if(this.isColumn) return `${this.shortOperator} ${this.expressionValue}`;
        if(this.isColumn) return `${this.expressionValue}`;
        if(this._dateExpression) return this.dateExpressionObject.title;
         if (['isnull', 'isnotnull', 'isnotblank', 'isblank', 'not_exists_clause'].indexOf(this.operator) > -1) {
            return this.operatorTitle;
        }
  
         if (['inlist', 'notinlist','exists_clause'].indexOf(this.operator) > -1 && this.selectedValue.constructor == Array) {
            if (+this.selectedValue.length > 0)
                return this.operatorTitle + " (" + this.selectedValue.length + ")";
        }
        if (this.valueType==="number" && this.operator.indexOf("between") > -1 && this.selectedValue.constructor == Array) {
            return "("+this.selectedValue.join(" - ")+")";
        }
        if ((this.isDate || this.valueType === 'time') && this.operator.indexOf("between") > -1 && this.selectedValue.constructor == Array) {
           return this.operatorTitle + "(...)";
        }

        if(this.operator === "exists_clause") return null;

        return null;
    }

    get shortDisabledValue() {
        if (!this.selectedValue) return null;
        if (this.isBit && this.selectedValue) return this.operatorTitle;
        if(this._dateExpression) return this.dateExpressionObject.title;
      //  if (this.operator == this.defaultOperator && !this._applied) return null;
        
        if (['isnull', 'isnotnull', 'isnotblank', 'isblank', 'not_exists_clause'].indexOf(this.operator) > -1) {
            return this.operatorTitle;
        }

        if (['inlist', 'notinlist'].indexOf(this.operator) > -1 && this.selectedValue.constructor == Array) {
            if (+this.selectedValue.length > 0)
                return "(" + this.selectedValue.length + ")";
        }
        if (this.operator.indexOf("between") > -1) return this.operatorTitle + "(...)";
        //return this.disabledValue ? this.disabledValue.replace("[" + this.caption + "] ", "") : null;
        return  null;
    }

    get shortLabel() {
        return this.label?this.label.replace("[" + this.caption + "] ", ""):this.label;
    }

    get placeholder(){
       // if(this.searchFunction) return this.caption + ' ' + $t('only');
        return null;
    }

    set column(pName) {
        this._column = pName;
    }

    get operators() {
        if (this._operators.length == 0) this._operators = getOperatorsList(this.valueType);

        return this._operators;
    }

    get limitedOperators() {
        if (this.valueType === "string") return this.operators.filter(x => ['inlist', 'notinlist', 'beginswith', 'endswith'].indexOf(x.name) === -1);
        if (this.valueType === "number") return this.operators.filter(x => ["greaterthanorequal", "lessthanorequal", "greaterthan", "lessthan", "between", "notbetween"].indexOf(x.name) === -1);
        if (this.isDate) return this.operators.filter(x => ["dategreaterthanorequal", "datelessthanorequal", "datebetween", "datenotbetween"].indexOf(x.name) === -1);

        return this.operators;
    }


    get label() {
        
        if (!this.selectedValue) return null;
        if (!this.expressionValue) return null;
        if(this.isNullValueOperatorAsValue) return null;
        return this._prettyString;
        //return prettifyFilterItem(this.item, { name:this.column,caption: this.caption }).replace(/#/g, "");
    }

    get operatorTitle() {
        return this.getOperatorTitle(this.operator);
    }
    get exist(){
        return this._existsObject;
    }

    get item() {
        return {
            column: this.convertedColumn,
            operator: this.operator,
            type: this.type,
            value: this.value,
            expressionValue: this.expressionValue,
            valueType: this.valueType,
            dateExpression: this._dateExpressionObject,
            existsObject:this._distinctHandler && this._distinctHandler.existsBinding?this._distinctHandler.existsObject:undefined,
            exists:this._existsObject,
            useExist:this.useExist,
            searchFunction:this._searchFunction,
            fullText:this._fullText
           // distinctHandler:this._distinctHandler && this._distinctHandler.existsBinding?this._distinctHandler:undefined

        }

    }

    get convertedColumn(){
        if(this.valueType === "number" && this.value !== null && isNaN(parseInt(this.value))){
            return `ToStr(${this.column})`;
        }

        return this.alias;
    }

    get caption() {
        if (this.options) return this.options.caption;
        return this.column;
    }


    get isInputEditor() {
        return this.value && typeof this.value == 'string' ? this.value.startsWith("{{") && this.value.endsWith("}}") : false;
    }

    get isColumn() {
        return this.value && typeof this.value == 'string' ? new RegExp(/^\[[a-zA-Z0-9\- ]+\]$/).test(this.value) : false;
    }

    get columnName() {
        return this._column;
    }
    get valueType() {
       
        if (this.options) return this.options.type;

        return 'string';
    }

    get distinctType(){
         if(this.distinctTargetColumnType){
            return this.distinctTargetColumnType;
        }

        return this.valueType;
    }

    get originalType(){
         if (this.options) return this.options.type;

        return 'string';
    }

    get filterDropdown() {
        if (this.options) return this.options.filterDropdown;

        return null;
    }

    get value() {
        if (this._nullValueOperators.indexOf(this.operator) > -1) {
            return null;
        }

        return this.selectedValue;
    }

    get filterEditor() {
        if (this.options && this.options.filterEditor) return this.options.filterEditor;

        if (this.options.distinct) return "Distinct";
        if (this.isBit) return this.isExtarnal ? "BitOrNotExists" : "Bit";
        if (this.isDate) return "Date";
        if (this.isTime) return "Time";
        return stringUtils.camelCase(this.valueType);
    }

    get distinctHandler() {
        
        if (!this.options.distinct) return null;
        if (!this._distinctHandler) {
            this._distinctHandler = new DistinctHandler(this.distinctOptions,this.exist);

            this._updateInitialDistinctOptions();
        }

        return this._distinctHandler;

    }

    get selectedValue() {
        if (!this._selectedValue) {
            if (this._nullValueOperators.indexOf(this.operator) > -1) {
                this._selectedValue=  this.operator;
            }
        }
        return this._selectedValue;
    }

    get defaultOperator() {
        if(this._defaultOperator) return this._defaultOperator;
        return getDefaultOperator(this.valueType);
    }
    set defaultOperator(pOperator:string) {
        this._defaultOperator = pOperator;
        this.operator = pOperator;
       
    }

    set selectedValue(pValue) {
        if(pValue && this.valueType == "time" && pValue.hasOwnProperty("hours")){
            this.operator = "timeequals";
            pValue = pValue.hours + ":" +pValue.minutes;
        }
        if (pValue === '') {
            this._selectedValue = undefined;
        } else {
            this._selectedValue = pValue;
        }
        if(this.selectedValue && this.isDate && this.operator.indexOf("between") > -1 && this.selectedValue.constructor != Array) {
            this.operator = "dateequals";
        }
        if (this.isBit) {
            if (pValue === 'istrue' || pValue === 'isfalse') {
                this.operator = pValue
            }
        }


        if (this._selectedValue === null) {
            this._applied = false;
        }

        if(pValue && this.searchFunction && this.searchFunction.isSet) this.searchFunction.value = null;

        this._prettyString = prettifyFilterItem(this.item, { name:this.column,caption: this.caption }).replace(/#/g, "");

    }

    get isNullValueOperator() {
        return this._nullValueOperators.indexOf(this.operator) > -1;
    }
    get isNullValueOperatorAsValue() {
        return this._nullValueOperators.indexOf(this._selectedValue) > -1;
    }



    get isOperatorLimited() {
        return this.limitedOperators.find(x => x.name === this.operator);
    }

    get limitedValue() {
        if(this._dateExpressionObject && this._dateExpressionObject.value && this._dateExpressionObject.value.constructor != Array){
            return getDateExpressionValue(this._dateExpressionObject.value);
        }
        if (this.selectedValue && !this._limitedValue && this.isOperatorLimited) {
            this._limitedValue = this._selectedValue;
        }
        return this._limitedValue;;
    }


    set limitedValue(pValue) {

       
        this._limitedValue = pValue;
        this._selectedValue = pValue;


    }

    get expressionValue(){
        //if(this._selectedValue && !this._expressionValue && !this.isInListUsed && this._selectedValue.length > 1) return this._selectedValue;
        return this._expressionValue;
    }
    set expressionValue(pValue){
        if(pValue == ''){
            pValue = null;
        }
        this._expressionValue = pValue;
        if(pValue && this.searchFunction && this.searchFunction.isSet) this.searchFunction.value = null;
    }

    get combinedStartValue() {
        if(this._dateExpressionObject && this._dateExpressionObject.value && this._dateExpressionObject.value.constructor == Array){
            return getDateExpressionValue(this._dateExpressionObject.value[0]);
        }
        return this._combinedStartValue;
    }

    set combinedStartValue(pValue) {
         if(this._dateExpressionObject && this._dateExpressionObject.value && this._dateExpressionObject.value.constructor == Array){
             this._combinedEndValue =  getDateExpressionValue(this._dateExpressionObject.value[1]);
            this._dateExpression = null;
            this._dateExpressionObject = null;
         }
        this._expressionValue = null;
        this._combinedStartValue = pValue;


    }
    get combinedEndValue() {
        if(this._dateExpressionObject && this._dateExpressionObject.value && this._dateExpressionObject.value.constructor == Array){
            return getDateExpressionValue(this._dateExpressionObject.value[1]);
        }
        return this._combinedEndValue;
    }

    set combinedEndValue(pValue) {
        if(this._dateExpressionObject && this._dateExpressionObject.value && this._dateExpressionObject.value.constructor == Array){
             this._combinedStartValue =  getDateExpressionValue(this._dateExpressionObject.value[0]);
            this._dateExpression = null;
            this._dateExpressionObject = null;
        }
        this._expressionValue = null;
        this._combinedEndValue = pValue;

    }

    get applied(){
        if(this.searchFunction && this.searchFunction.isSet) return true;
        return this._applied
    }
    set applied(pValue){
        this._applied = pValue;
    }

    get dateExpression(){
        return this._dateExpression;
    }
    set dateExpression(pValue){
        this._dateExpression = pValue;
        this._dateExpressionObject = getCombinedExpressionByID(this._dateExpression)
        
        if(this._dateExpressionObject) this.selectedValue = this._dateExpressionObject.value;
        this.operator = this._dateExpressionObject?this._dateExpressionObject.operator:this.defaultOperator;
        

    }

    get dateExpressionObject(){
        return this._dateExpressionObject;
    }

   

    constructor(pOptions:any) {
        this._column = pOptions.column ?? pOptions.field;
        this.options = pOptions;
        this._operator = pOptions.operator ?? getDefaultOperator(this.valueType);
    }
    setAsApplied(){
        this._applied = true;
    }

    getDateExpression(pValue:string){
        return tryGeCombinedtDateExpression(pValue);
    }

    resetValue(){
        this._applied = false;
        this.selectedValue = null;
        this._limitedValue = null;
        this._combinedEndValue = null;
        this._combinedStartValue = null;
        this._dateExpression = null;
        this._dateExpressionObject = null;
        this._expressionValue = null;
    }
    resetItem() {
        this.setToDefaultOperator();
        this.resetValue();

        if(this.searchFunction){
            this.searchFunction.value = null;
        }
        
       // this.updated = new Date();
    }

    setToDefaultOperator() {
        this.operator = this.defaultOperator;
    }

    _updateInitialDistinctOptions(){
        if(!this._distinctHandler) return;
        Object.keys(this._distinctOptions).forEach(key=>{
            this._distinctHandler[key] = this._distinctOptions[key]
        })
    }
    setDistinctOptions(pOptions:any){
        Object.keys(pOptions).forEach(key=>{
            this._distinctOptions[key] = pOptions[key];
        });
        this._updateInitialDistinctOptions();
        if(this._distinctOptions.targetColumn){
            this.distinctTargetColumn = this._distinctOptions.targetColumn;
        }
        if(this._distinctOptions.targetColumn && ! this._distinctOptions.targetColumnType && this._distinctOptions.targetColumn.endsWith("_ID")){
            this._distinctOptions.targetColumnType = "number"
        }
    }

    setExistsOptions(pOptions:any){
        this._existsObject = new ExistsObject(this.column,pOptions);
      //  if (this.operators.findIndex(x => x.name == 'not_exists_clause') === -1) {
          //  this.operators.push({ name: 'not_exists_clause', title: getOperatorTitle('not_exists_clause') });
       // }
    }

     setSearchFunction(pOptions:any){
        pOptions.column = this.column;
        this._searchFunction = new Proxy(new SearchFunction(pOptions),{
            set: (obj, prop, value) => {
                    Reflect.set(obj, prop, value);
                    if (prop == "value") this.resetValue();
                  
                    return true;
                }
        });
      //  this.operators.unshift({ name: 'search_fnc', title: getOperatorTitle('search_fnc')});
       // this.operator = "search_fnc";

    }

    setFullText(pSearchFunction:any){
        this._fullText = new FullText(this.column,pSearchFunction);
        this.operators.unshift({ name: 'full_text', title: getOperatorTitle('full_text')});
        this.defaultOperator = "full_text";
    }

    updateFromServer(pProps:any) {
        if(this.selectedValue && pProps.operator == "contains" && this.operator == "full_text"){
            this._selectedValue += " " + pProps.selectedValue;
            this._expressionValue = this.selectedValue;
            this.applied = true;
            return;
        }
        if(pProps.expressionValue){
            this._expressionValue = pProps.expressionValue;
        }

        if (pProps['useAlias']) {
            this.useAlias = true;
        }
        
        if (pProps.hasOwnProperty("operator")) {
            this.operator = pProps.operator;
        }

        if(pProps.operator == "exists_clause"){
         /*   this.distinctHandler.existsBinding = pProps.selectedValue[0].binding?.replace("T1.","T1_.").replace("T2.","T1.").replace("T1_.","T2.");
            Object.keys(pProps.selectedValue[0]).forEach(key=>{
                this.distinctHandler[key] = pProps.selectedValue[0][key];
            })*/
            
           // this.distinctHandler
           // this.selectedValue = parseValueFromServer(pProps.selectedValue[1], this.valueType);
            if(pProps.selectedValue && pProps.selectedValue.length == 2 && pProps.selectedValue[0].hasOwnProperty("binding")){
                pProps.selectedValue = pProps.selectedValue[1];
            }
            this.selectedValue = parseValueFromServer(pProps.selectedValue, this.valueType);
            return;
        }
        if (pProps.hasOwnProperty("selectedValue")) {
            if(typeof pProps.selectedValue == "string"){
                pProps.selectedValue = pProps.selectedValue.replaceAll("'","");
            }
            pProps.selectedValue = parseValueFromServer(pProps.selectedValue, this.valueType);
            const vDateExpression = this.getDateExpression(pProps.selectedValue);
            if(pProps.selectedValue && pProps.selectedValue.constructor == Array && !vDateExpression){
              //  this.selectedValue = parseValueFromServer(pProps.selectedValue, this.valueType);
                this.selectedValue = pProps.selectedValue;
                this.combinedStartValue = pProps.selectedValue[0];
                this.combinedEndValue = pProps.selectedValue[1];
            }

            if(vDateExpression){
                this._dateExpressionObject = vDateExpression;
                this.dateExpression = vDateExpression.id;
                //this.selectedValue = parseValueFromServer(pProps.selectedValue, this.valueType);
                this.selectedValue = pProps.selectedValue;
            // this._dateExpressionObject = vDateExpression;
                return;
            }
            if(this.isNullValueOperator){
                this.selectedValue = pProps.operator;
                return;
            }
            this.selectedValue = pProps.selectedValue;
        }
        if (this.isBit) {
            if (this._selectedValue === 0) {
                this.operator = 'isfalse';
                
            }
            if (this._selectedValue === 1) {
                this.operator = 'istrue';
                
            }
            this.selectedValue = this.operator;
        }
        if(this.valueType === "string" && this.operator === 'notequals'){
            if(!this.selectedValue){
                this.operator = 'isnotblank';
                this.selectedValue = this.operator;
            }
        }
       
        if(this.isOperatorLimited && this.operator != "full_text"){
            this.limitedValue = this.selectedValue;
        }
        if(this.isInListUsed){
            this._expressionValue = this.selectedValue;
        }
        if(this.operator == "full_text"){
            this._expressionValue = this.selectedValue;
        }

        this.applied = true;

    }

    updateProps(pProps:any) {

        Object.keys(pProps).forEach(key => {
            if (key === "selectedValue") {
                this.selectedValue = parseValueFromServer(pProps[key], this.valueType);
            } else {
                this[key] = pProps[key];
            }
        });


    }

    getExpression() {
        return filterItemToString(this.item);
    }
    /**parse string, expression */
    parseValue(pValue:any){
   
        if(this.isInListUsed && this.valueType == "string" && this.distinctTargetColumnType == "number" && isNumbersInList(pValue.split(",").map((x:string)=>x.trim()))){
             return {
                value:pValue.constructor == Array?pValue:pValue.split(",").filter((x:string)=>x.length).map((x:string)=>parseInListNumber(x.trim())),
                operator:this.operator
            }
        }
        if(this.isInListUsed && this.valueType == "string"){
              return {
                value:pValue.constructor == Array?pValue:pValue.split(",").map((x:string)=>x.trim().replace(/'/g,"")),
                operator:this.operator
            }
        }

         if(this.isInListUsed && this.valueType == "number"){
              return {
                value:pValue.constructor == Array?pValue:pValue.split(",").map((x:string)=>parseInListNumber(x.trim())),
                operator:this.operator
            }
        }
        if(this.valueType == "string"){
            return {
                value:this.parseString(pValue),
                operator:this.operator
            }
        }
        if(this.valueType == "number" && this.options?.dataType !== 'decimal'){
            return this.parseNumber(pValue);
        }
        if(this.valueType == "number" && this.options?.dataType == 'decimal'){
            return this.parseDecimal(pValue);
        }
        return pValue;
    }


    parseString(pValue:any) {
        if (pValue && pValue.constructor === String) {
            let vNotMode = pValue.startsWith("!");


            if (pValue.startsWith("=")) {
                this.operator = 'equals';
                return pValue.substring(1, pValue.length).trim();
            }
            if (pValue.startsWith("!=")) {
                this.operator = 'notequals';
                return pValue.substring(2, pValue.length).trim();
            }
            if (pValue.startsWith("<>")) {
                this.operator = 'notequals';
                return pValue.substring(2, pValue.length).trim();
            }
            if (vNotMode) {
                pValue = pValue.substring(1, pValue.length);
            }
            if (pValue.startsWith("%") && pValue.endsWith("%")) {
                this.operator = 'contains';
                if (vNotMode) {
                    this.operator = 'notcontains';
                }
                return pValue.substring(1, pValue.length - 1);
            }

            if (pValue.endsWith("%")) {
                this.operator = 'beginswith';
                if (vNotMode) {
                    this.operator = 'notbeginswith';
                }

                return pValue.substring(0, pValue.length - 1);
            }
            if (pValue.startsWith("%")) {
                this.operator = 'endswith';
                if (vNotMode) {
                    this.operator = 'notendswith';
                }

                return pValue.substring(1, pValue.length);
            }
        }
        // this.operator = this.defaultOperator;
        return pValue;
    }
    parseNumber(pValue:any) {
        const vReturn = {
            value:pValue,
            operator:this.operator
        }
        if (!pValue) return vReturn;
        if (['isnull', 'isnotnull'].indexOf(pValue) > -1) {
            return vReturn;
        }
       /* if (pValue.indexOf("-") > -1) {
            vReturn.operator = 'between';
            pValue = pValue.split("-");
            vReturn.value = pValue.map(x => x.trim())
            return vReturn;
        }*/

        if(pValue.indexOf(",") > -1){
            vReturn.operator = 'inlist';
            pValue = pValue.split(",");
            vReturn.value = pValue.map((x: string) => x.trim());
            return vReturn;
        }

        if (isNaN(parseInt(pValue))) {
            if (pValue.indexOf("<>") > -1) {
                vReturn.operator = 'numbernotequals';
                pValue = pValue.replace("<>", "").trim();
                return parseInt(pValue);
            }
            if (pValue.indexOf("<=") > -1) {
                vReturn.operator = 'lessthanorequal';
                pValue = pValue.replace("<=", "").trim();
                vReturn.value = parseInt(pValue);
                return vReturn;
            }
            if (pValue.indexOf(">=") > -1) {
                vReturn.operator = 'greaterthanorequal';
                pValue = pValue.replace(">=", "").trim();
                 vReturn.value = parseInt(pValue);
                return vReturn;
            }
            if (pValue.indexOf("<") > -1) {
                vReturn.operator = 'lessthan';
                pValue = pValue.replace("<", "").trim();
                 vReturn.value = parseInt(pValue);
                return vReturn;
            }
            if (pValue.indexOf(">") > -1) {
                vReturn.operator = 'greaterthan';
                pValue = pValue.replace(">", "").trim();
                 vReturn.value = parseInt(pValue);
                return vReturn;
            }
            if (pValue.indexOf("!=") > -1) {
                vReturn.operator = 'numbernotequals';
                pValue = pValue.replace("!=", "").trim();
                 vReturn.value = parseInt(pValue);
                return vReturn;
            }
            if (pValue.indexOf("=") > -1) {
                vReturn.operator = 'equals';
                pValue = pValue.replace("=", "").trim();
                 vReturn.value = parseInt(pValue);
                return vReturn;
            }
            console.warn("Failed to parse number value: ", pValue);
            return vReturn;
        }
        vReturn.operator = this.operator;
        return vReturn;

    }
    parseDecimal(pValue:any) {
        const vReturn = {
            value:pValue,
            operator:this.operator
        }
        if (!pValue) return vReturn;
        if (['isnull', 'isnotnull'].indexOf(pValue) > -1) {
            return vReturn;
        }
       /* if (pValue.indexOf("-") > -1) {
            vReturn.operator = 'between';
            pValue = pValue.split("-");
            vReturn.value = pValue.map(x => x.trim())
            return vReturn;
        }*/

        if(pValue.indexOf(",") > -1){
            vReturn.operator = 'inlist';
            pValue = pValue.split(",");
            vReturn.value = pValue.map((x: string) => x.trim());
            return vReturn;
        }

        if (isNaN(parseFloat(pValue))) {
            if (pValue.indexOf("<>") > -1) {
                vReturn.operator = 'notequals';
                pValue = pValue.replace("<>", "").trim();
                return parseFloat(pValue);
            }
            if (pValue.indexOf("<=") > -1) {
                vReturn.operator = 'lessthanorequal';
                pValue = pValue.replace("<=", "").trim();
                vReturn.value = parseFloat(pValue);
                return vReturn;
            }
            if (pValue.indexOf(">=") > -1) {
                vReturn.operator = 'greaterthanorequal';
                pValue = pValue.replace(">=", "").trim();
                 vReturn.value = parseFloat(pValue);
                return vReturn;
            }
            if (pValue.indexOf("<") > -1) {
                vReturn.operator = 'lessthan';
                pValue = pValue.replace("<", "").trim();
                 vReturn.value = parseFloat(pValue);
                return vReturn;
            }
            if (pValue.indexOf(">") > -1) {
                vReturn.operator = 'greaterthan';
                pValue = pValue.replace(">", "").trim();
                 vReturn.value = parseFloat(pValue);
                return vReturn;
            }
            if (pValue.indexOf("!=") > -1) {
                vReturn.operator = 'notequals';
                pValue = pValue.replace("!=", "").trim();
                 vReturn.value = parseFloat(pValue);
                return vReturn;
            }
            if (pValue.indexOf("=") > -1) {
                vReturn.operator = 'equals';
                pValue = pValue.replace("=", "").trim();
                 vReturn.value = parseFloat(pValue);
                return vReturn;
            }
            console.warn("Failed to parse number value: ", pValue);
            return vReturn;
        }
        vReturn.operator = this.operator;
        return vReturn;

    }

    getOperatorTitle(pOperator:string) {
        return getOperatorTitle(pOperator);
    }

    clear() {
        this.resetItem();
    }
}